1<template>
  <div class="body">
    <div class="login flex">
      <!-- <img src="@/assets/img/login_user.png" alt="" class="left-img" /> -->
      <div class="content-r">
        <div class="title">公文流转系统</div>
        <div><img src="@/assets/img/qywxcode.png" alt="" class="code" /></div>
        <div class="qywx">企业微信扫码登录</div>
        <div class="go-app">
          <a href="javascript:;" @click="goapp"
            ><img
              src="https://open.work.weixin.qq.com/service/img?id=wwdbef0913f3a4e57c&t=login&c=blue&s=large"
              srcset="https://open.work.weixin.qq.com/service/img?id=wwdbef0913f3a4e57c&t=login&c=blue&s=large@2x 2x"
              referrerpolicy="unsafe-url"
              alt="企业微信"
          /></a>
        </div>
        <div class="sm">
          <span>帮助</span>
          <span>隐私</span>
          <span>条款</span>
        </div>
        <div class="sm">Copyright 2020 贵州丑橘科技有限公司All rights reserved</div>
      </div>
    </div>
  </div>
</template>



<script>
import { serverUrl } from '@/mixins/config.js'
export default {
  data() {
    return {}
  },
  methods: {
    goapp() {
      const url =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww8415e9f193398552&redirect_uri=http%3A%2F%2F' +
        serverUrl +
        '%2F&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      window.location.href = url
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-around;
}
.font {
  font-size: 16px;
}
.body {
  background: url(~@/assets/img/loginbg.png);
  width: 1922px;
  height: 1082px;
}
.login {
  position: relative;
  top: 266px;
  left: 400px;
  width: 1100px;
  .left-img {
    width: 506px;
    height: 641px;
  }
  .title {
    font-size: 50px;
    font-family: FZLanTingHei-DB-GBK;
    font-weight: 400;
    color: #090909;
  }
  .code {
    width: 342px;
    height: 342px;
  }
  .qywx {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-bottom: 20px;
  }
  .content-r {
    text-align: center;
  }
  .sm {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    color: #a9a9a9;
    line-height: 36px;
    span {
      margin: 0 20px;
    }
  }
}
.go-app {
  margin-bottom: 20px;
}
</style>